import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '../../../components/ui';

export const ReturnToSignInLink = () => (
  <div className="flex flex-col items-center mt-6 text-center">
    <p className="text-sm text-slate-500">
      <Link
        to="/auth/login"
        className="flex items-center gap-1 px-2 py-1 text-sm font-medium transition rounded text-slate-600 group u-ring"
      >
        <FontAwesomeIcon icon={faArrowLeft} fixedWidth />
        <span className="transition group-hover:underline underline-offset-4">Return to sign in</span>
      </Link>
    </p>
  </div>
);
