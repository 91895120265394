import { PropsWithChildren, createContext, useMemo, useState } from 'react';

type PaginationContextValue = {
  pageIndex: number;
  pageSize: number;
  getPageCount: (totalRows: number) => number;
  changePageIndex: (pageIndex: number) => void;
  changePageSize: (pageSize: number) => void;
};

const PaginationContext = createContext<PaginationContextValue>({
  pageIndex: 0,
  pageSize: 10,
  getPageCount: () => 0,
  changePageIndex: () => void 0,
  changePageSize: () => void 0,
} as PaginationContextValue);

const PaginationProvider = ({ children }: PropsWithChildren) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const actions = useMemo(() => {
    const getPageCount = (totalRows: number) => Math.ceil(totalRows / pageSize);

    const changePageIndex = (pageIndex: number) => {
      setPageIndex(pageIndex);
    };

    const changePageSize = (pageSize: number) => {
      setPageSize(pageSize);
    };

    return { pageIndex, pageSize, getPageCount, changePageIndex, changePageSize };
  }, [pageIndex, pageSize]);

  return <PaginationContext.Provider value={actions}>{children}</PaginationContext.Provider>;
};

export { PaginationContext, PaginationProvider };
