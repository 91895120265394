import { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { FullPageSpinner } from '../components/ui';
import store, { persistor } from '../store';

export const StoreProvider = ({ children }: PropsWithChildren) => (
  <Provider store={store}>
    <PersistGate loading={<FullPageSpinner />} persistor={persistor}>
      {children}
    </PersistGate>
  </Provider>
);
