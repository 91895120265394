const Logo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="489" height="426" fill="none" viewBox="0 0 489 426" role="img" {...props}>
    <path
      fill="#2970FF"
      d="M124.286 0a53.14 53.14 0 0126.722 7.457 53.222 53.222 0 0119.338 19.913c.851 1.704 91.801 177.429 125.734 233.341 46.699 77.319 78.504 92.548 148.286 126.096 11.808 5.751 24.679 11.928 38.827 19.063a10.658 10.658 0 012.027 17.63 10.632 10.632 0 01-6.389 2.499H195.876L81.736 0h42.55zM2.594 3.834A10.639 10.639 0 0110.784 0h48.933l114.139 425.999h-88.61a10.627 10.627 0 01-6.918-2.414 10.65 10.65 0 01-3.719-6.319L.147 12.567a10.662 10.662 0 012.447-8.733z"
    ></path>
  </svg>
);

export default Logo;
