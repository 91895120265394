import { createContext, PropsWithChildren, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useSearchParam } from 'react-use';

type TableSortContextValue = {
  currentSortKey: string;
  sort: (sortKey: string, defaultSortDirection: 'asc' | 'desc') => void;
};

const TableSortContext = createContext<TableSortContextValue>({} as TableSortContextValue);

function invertSortKey(sortKey: string) {
  return sortKey.startsWith('-') ? sortKey.replace(/^-/, '') : `-${sortKey}`;
}

function createSortKey(sortKey: string, defaultSortDirection: 'asc' | 'desc') {
  return defaultSortDirection === 'asc' ? sortKey : `-${sortKey}`;
}

type Props = {
  readonly defaultSortKey: string;
};

const TableSortProvider = ({ defaultSortKey, children }: PropsWithChildren<Props>) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentSortKey = useSearchParam('sort') ?? defaultSortKey;

  const sort = useCallback(
    (sortKey: string, defaultSortDirection: 'asc' | 'desc') => {
      const currentQueryParams = new URLSearchParams(location.search);
      const newSortKey = currentSortKey === sortKey ? invertSortKey(sortKey) : createSortKey(sortKey, defaultSortDirection);

      currentQueryParams.set('sort', newSortKey);
      navigate({ search: currentQueryParams.toString() });
    },
    [currentSortKey, location.search, navigate],
  );

  const value = useMemo(() => ({ currentSortKey, sort }), [currentSortKey, sort]);

  return <TableSortContext.Provider value={value}>{children}</TableSortContext.Provider>;
};

export { TableSortContext, TableSortProvider };
