import { lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import { config } from '../../../config';
import { useJune } from '../../../hooks';
import { useIsLoggedIn } from '../../../store/auth.slice';
import { Toaster } from '../../ui';
import GuestApp from './GuestApp';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

const App = () => {
  const isLoggedIn = useIsLoggedIn();

  useJune(config.juneWriteKey);

  return (
    <>
      <Helmet defaultTitle="Tailplane" titleTemplate="%s - Tailplane" />

      {isLoggedIn ? <AuthenticatedApp /> : <GuestApp />}

      <Toaster />
    </>
  );
};

export default App;
