/* eslint-disable @typescript-eslint/no-base-to-string */
import { FocusRing } from '@react-aria/focus';
import { AriaLinkOptions, useLink } from '@react-aria/link';
import { PropsWithChildren, useRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

type LinkProps = PropsWithChildren<RouterLinkProps & React.RefAttributes<HTMLAnchorElement>>;

const Link = ({ to, className, target, tabIndex, children, ...props }: LinkProps) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const { linkProps } = useLink(props as AriaLinkOptions, ref);
  const isExternal = to && ['http', 'https', 'mailto'].some((prefix) => to.toString().startsWith(prefix));
  const Component = isExternal ? (
    <a href={to.toString()} {...linkProps} target={target} ref={ref} className={className}>
      {children}
    </a>
  ) : (
    <RouterLink {...linkProps} tabIndex={tabIndex} to={to} ref={ref} className={className}>
      {children}
    </RouterLink>
  );

  return <FocusRing focusRingClass="u-focus-ring">{Component}</FocusRing>;
};

export default Link;
