import { Navigate, Route, Routes } from 'react-router-dom';
import { Auth } from '../../../modules/Auth';

const GuestApp = () => (
  <Routes>
    <Route path="auth/*" element={<Auth />} />
    <Route path="*" element={<Navigate to="auth" />} />
  </Routes>
);

export default GuestApp;
