/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { OrganizationSummary, UserProfile } from '../api';
import { config } from '../config';
import { useJune } from './useJune';

export const useAnalytics = () => {
  const analytics = useJune(config.juneWriteKey);

  const track = useCallback(
    (event: string, properties?: Record<string, string | number | boolean>) => {
      if (!analytics) {
        return;
      }

      void analytics.track(event, properties).then();
    },
    [analytics],
  );

  const identify = useCallback(
    (user: UserProfile, organization?: OrganizationSummary) => {
      if (analytics) {
        void analytics
          .identify(user.id, {
            email: user.email,
            name: `${user.firstName} ${user.lastName}`.trim(),
            firstName: user.firstName,
            lastName: user.lastName,
            avatar: user.avatarUrl ?? undefined,
            phone: user.formattedPhoneNumber,
          })
          .then();

        if (organization) {
          void analytics
            .group(organization.id, {
              name: organization.name,
            })
            .then();
        }
      }

      if ((window as any).Beacon) {
        (window as any).Beacon('identify', {
          name: `${user.firstName} ${user.lastName}`.trim(),
          email: user.email,
          avatar: user.avatarUrl,
        });
      }
    },
    [analytics],
  );

  return { track, identify };
};
