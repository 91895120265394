import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PasswordResetsService {
  /**
   * Reset user password
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static resetPassword(requestBody: { email?: string; token?: string; newPassword?: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/password-resets',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        418: `Unprocessable Entity`,
        500: `Internal Server Error`,
      },
    });
  }
}
