import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthLayout } from '../../components/layouts';
import { ChangePassword } from './views/ChangePassword';
import { Login } from './views/Login';
import { ResetPassword } from './views/ResetPassword';

const Auth = () => (
  <AuthLayout>
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="change-password/:token" element={<ChangePassword />} />
      <Route path="*" element={<Navigate to="login" />} />
    </Routes>
  </AuthLayout>
);

export default Auth;
