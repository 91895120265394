import * as Sentry from '@sentry/react';
import axios from 'axios';
import React from 'react';
import 'react-day-picker/dist/style.css';
import ReactDOM from 'react-dom/client';
import { OpenAPI } from './api';
import { App } from './components/base/App';
import { config } from './config';
import './css/index.css';
import { AppProvider } from './providers';
import { tokenStorageKey } from './services/AuthService';

if (config.telemetryEnabled) {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.environment,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

axios.defaults.baseURL = config.apiUrl;

OpenAPI.BASE = config.apiUrl;
OpenAPI.TOKEN = () => Promise.resolve(`${localStorage.getItem(String(tokenStorageKey))}`);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
);
