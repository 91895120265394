export enum QueryKeys {
  AERODROMES = 'aerodromes',
  AIRCRAFT = 'aircraft',
  AIRCRAFT_DATA_IMPORTS = 'aircraft_data_imports',
  AIRCRAFT_DOCUMENTS = 'aircraft_documents',
  AIRCRAFT_UTILIZATION_REPORT = 'aircraft_utilization_report',
  BOOKINGS = 'bookings',
  EMPLOYEE_DOCUMENTS = 'employee_documents',
  INSTRUCTOR_ACTIVITY_REPORT = 'InstructorActivityReport',
  INSTRUCTOR_SUMMARIES = 'instructor_summaries',
  LOCATIONS = 'locations',
  MEMBER_DOCUMENTS = 'member_documents',
  MEMBERS = 'members',
  RESET_PASSWORD_TOKEN_VERIFICATION = 'reset_password_token_verification',
  USERS = 'users',
}
