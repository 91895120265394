import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PasswordResetRequestsService {
  /**
   * Create a password reset request
   * @param requestBody
   * @returns any Successfully created password reset request
   * @throws ApiError
   */
  public static create(requestBody: { email?: string; client?: 'webapp' | 'bookings' }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/password-reset-requests',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Internal Server Error`,
      },
    });
  }
}
