import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../enums';
import { ProblemDetails } from '../../types';
import { axiosPost } from '../../utils';

export const useVerifyResetPasswordToken = (email: string, token: string) =>
  useQuery<boolean, ProblemDetails>({
    queryKey: [QueryKeys.RESET_PASSWORD_TOKEN_VERIFICATION, { email, token }],
    queryFn: () => axiosPost('/password-reset-link-validations', { email, token }),
    gcTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
  });
