import { IconDefinition, faCheckCircle, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '../../../utils/cn';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from './Toast';
import { useToast } from './useToast';

const iconMap: Record<'positive' | 'destructive' | 'default', Nullable<IconDefinition>> = {
  positive: faCheckCircle,
  destructive: faExclamationCircle,
  default: null,
};

export const Toaster = () => {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, className, ...props }) {
        return (
          <Toast key={id} className={cn('mt-4', className)} {...props}>
            <div className="flex items-start">
              {props.variant && iconMap[props.variant] && (
                <div className="mr-3">
                  <div className="inline-flex p-0.5 border rounded-full group-[.destructive]:border-error-300 group-[.positive]:border-success-300 ring-1 ring-offset-2 ring-opacity-50 group-[.destructive]:ring-error-300/50 group-[.positive]:ring-success-300/30">
                    <FontAwesomeIcon
                      icon={iconMap[props.variant]!}
                      className="w-auto h-4 group-[.positive]:text-success-600/80 group-[.destructive]:text-error-600"
                    />
                  </div>
                </div>
              )}
              <div>
                <div className="grid gap-1">
                  {title && <ToastTitle>{title}</ToastTitle>}
                  {description && <ToastDescription>{description}</ToastDescription>}
                </div>
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
};
