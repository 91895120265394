import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormikProps } from 'formik';
import { FormInput } from '../../../../components/forms';
import { SubmitButton } from '../../../../components/ui';
import { ProblemDetails } from '../../../../types';

type Props<T> = FormikProps<T> & {
  error: Nullable<ProblemDetails>;
};

export const ChangePasswordForm = <T,>({ error, isSubmitting }: Props<T>) => (
  <>
    <Form noValidate spellCheck={false} aria-label="Sign in to Tailplane" className="flex flex-col gap-4 mb-4">
      <div className="flex flex-col gap-1.5">
        <label htmlFor="password" id="password" className="w-full text-sm font-medium text-slate-700">
          Password
        </label>
        <FormInput type="password" placeholder="••••••••" name="newPassword" autoComplete="new-password" className="w-full" />
      </div>

      <div className="flex flex-col gap-1.5">
        <label htmlFor="password" id="password" className="w-full text-sm font-medium text-slate-700">
          Confirm password
        </label>
        <FormInput type="password" placeholder="••••••••" name="confirmPassword" autoComplete="new-password" className="w-full" />
      </div>

      <SubmitButton type="submit" isSubmitting={isSubmitting} className="w-full u-btn u-btn--primary">
        Change password
      </SubmitButton>
    </Form>

    {error && (
      <div className="flex p-4 text-sm border bg-error-25 text-error-700 border-error-300 rounded-xl">
        <div className="mr-3">
          <div className="inline-flex p-0.5 border rounded-full border-error-300 ring-1 ring-offset-2 ring-opacity-50 ring-error-300">
            <FontAwesomeIcon icon={faExclamationCircle} className="w-auto h-4 text-error-600" />
          </div>
        </div>
        <div>
          <div className="font-semibold">Password could not be changed</div>
          <div>An error has occurred. Please try again later.</div>
        </div>
      </div>
    )}
  </>
);
