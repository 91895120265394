import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '.';
import { LocationSummary, UserPreferences, UserProfile } from '../api';
import { RoleName } from '../types';

export interface AuthSliceState {
  token?: Nullable<string>;
  user: Nullable<UserProfile>;
  context?: {
    organizationId: string;
  };
}

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: null, user: null } as AuthSliceState,
  reducers: {
    setToken(state, { payload: token }: PayloadAction<Nullable<string>>) {
      state.token = token;
    },

    clearToken(state) {
      state.token = null;
    },

    setUser(state, { payload: user }: PayloadAction<Nullable<UserProfile>>) {
      state.user = user;
    },

    setContext(state, { payload: { organizationId } }: PayloadAction<{ organizationId: string }>) {
      state.context = { organizationId };
    },

    clearContext(state) {
      state.context = undefined;
    },

    updateUserProfile(state, { payload: user }: PayloadAction<UserProfile>) {
      state.user = { ...state.user, ...user };
    },

    updateUserPreferences(state, { payload: preferences }: PayloadAction<Partial<UserPreferences>>) {
      if (state.user) {
        state.user.preferences = { ...state.user.preferences, ...preferences };
      }
    },

    updateOrganizationSettings(
      state,
      { payload: { organizationId, settings } }: PayloadAction<{ organizationId: string; settings: { name: string; email: string } }>,
    ) {
      if (!state.user) {
        return;
      }

      state.user.memberships = state.user.memberships.map((membership) => {
        if (membership.organization.id === organizationId) {
          membership.organization = { ...membership.organization, ...settings };
        }

        return membership;
      });
    },

    addOrganizationLocation(
      state,
      { payload: { organizationId, location } }: PayloadAction<{ organizationId: string; location: LocationSummary }>,
    ) {
      if (state.user) {
        state.user.memberships = state.user.memberships.map((membership) => {
          if (membership.organization.id === organizationId) {
            membership.organization.locations.push(location);
          }

          return membership;
        });
      }
    },

    removeOrganizationLocation(
      state,
      { payload: { organizationId, locationId } }: PayloadAction<{ organizationId: string; locationId: string }>,
    ) {
      if (state.user) {
        state.user.memberships = state.user.memberships.map((membership) => {
          if (membership.organization.id === organizationId) {
            membership.organization.locations = membership.organization.locations.filter((location) => location.id !== locationId);
          }

          return membership;
        });
      }
    },
  },
});

const selectAuth = (state: RootState) => state.auth;

export const getAuthToken = createSelector(selectAuth, ({ token }) => token);
export const getCurrentUser = createSelector(selectAuth, ({ user }) => user);

export const useCurrentUser = () => useSelector(getCurrentUser)!;

const getIsLoggedIn = createSelector(getAuthToken, getCurrentUser, (token, user) => token !== null && user !== null);
export const useIsLoggedIn = () => useSelector(getIsLoggedIn);

export const usePreferences = () => useSelector(getCurrentUser)!.preferences;

export const useLocale = () => useSelector(getCurrentUser)?.preferences.locale ?? 'en-US';

const getInitialAuthContext = createSelector(selectAuth, ({ context }) => context);
export const useInitialAuthContext = () => useSelector(getInitialAuthContext);
const getMemberships = createSelector(selectAuth, ({ user }) => {
  if (!user) {
    return [];
  }

  return (
    user.memberships.filter((membership) => membership.roles.some((role) => [RoleName.Admin, RoleName.Instructor].includes(role))) ?? []
  );
});
export const useMemberships = () => useSelector(getMemberships);
export const getAuthContext = createSelector(selectAuth, getMemberships, ({ user, context }, memberships) => {
  if (memberships.length > 1) {
    return { organizationId: context?.organizationId };
  }

  if (!user?.memberships || user.memberships.length === 0) {
    return { organizationId: null };
  }

  return { organizationId: user?.memberships[0]!.organization.id };
});
export const getCurrentMembership = createSelector(getAuthContext, getMemberships, ({ organizationId }, memberships) =>
  memberships.find((m) => m.organization.id === organizationId),
);
export const useCurrentMembership = () => useSelector(getCurrentMembership)!;

export const useOrganizationId = () => useSelector(getCurrentMembership)!.organization.id;

export const {
  setToken,
  clearToken,
  setUser,
  setContext,
  clearContext,
  updateUserProfile,
  updateUserPreferences,
  updateOrganizationSettings,
  addOrganizationLocation,
  removeOrganizationLocation,
} = authSlice.actions;

export default authSlice;
