import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { FullPageSpinner, TooltipProvider } from '../components/ui';
import { AuthProvider } from '../context';
import { StoreProvider } from './StoreProvider';

const AppProvider = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<FullPageSpinner />}>
    <BrowserRouter>
      <TooltipProvider delayDuration={200}>
        <HelmetProvider>
          <QueryClientProvider client={new QueryClient()}>
            <StoreProvider>
              <AuthProvider>{children}</AuthProvider>
            </StoreProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </TooltipProvider>
    </BrowserRouter>
  </Suspense>
);

export default AppProvider;
