import * as Sentry from '@sentry/react';
import axios from 'axios';
import { AuthToken, UserProfile } from '../api';
import { config } from '../config';
import { axiosGet, axiosPost } from '../utils';

export const tokenStorageKey = 'auth.token';
export const emailStorageKey = 'auth.email';

const AuthService = {
  getToken: (): Nullable<string> => localStorage.getItem(tokenStorageKey),

  saveToken: (token: string): void => localStorage.setItem(tokenStorageKey, token),

  deleteToken: (): void => localStorage.removeItem(tokenStorageKey),

  getEmail: (): string => localStorage.getItem(emailStorageKey) ?? '',

  saveEmail: (email: string): void => localStorage.setItem(emailStorageKey, email),

  login: async (email: string, password: string): Promise<Nullable<UserProfile>> => {
    try {
      const { access_token: accessToken } = await axiosPost<{ username: string; password: string }, AuthToken>('/auth/token', {
        username: email,
        password,
      });

      AuthService.saveToken(accessToken);
      AuthService.saveEmail(email);

      return AuthService.getUser(accessToken);
    } catch (e) {
      return Promise.resolve(null);
    }
  },

  logout: async (): Promise<void> => {
    AuthService.deleteToken();

    if (config.telemetryEnabled) {
      Sentry.setUser(null);
    }

    return Promise.resolve();
  },

  async getUser(tokenOverride?: string): Promise<Nullable<UserProfile>> {
    const token = tokenOverride ?? AuthService.getToken();

    if (!token) {
      return Promise.resolve(null);
    }

    axios.defaults.headers.common = {
      authorization: `Bearer ${token}`,
    };
    try {
      const user = await axiosGet<UserProfile>('/auth/user')();

      if (config.telemetryEnabled) {
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });
      }

      return user;
    } catch (e) {
      return Promise.resolve(null);
    }
  },
};

export default AuthService;
