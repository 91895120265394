import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import * as Yup from 'yup';
import { useAuth } from '../../../../hooks/useAuth';
import { LoginForm } from './LoginForm';

type FormValues = {
  email: string;
  password: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  password: Yup.string().required(),
});

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [error, setError] = useState(false);
  const [defaultEmail] = useLocalStorage('auth.email', '');
  const initialValues: FormValues = {
    email: defaultEmail ?? '',
    password: '',
  };

  const submit = async ({ email, password }: FormValues, { setFieldValue }: FormikHelpers<FormValues>) => {
    try {
      const result = await login(email, password);

      if (!result) {
        setError(true);
        await setFieldValue('password', '');

        return;
      }
    } catch (e) {
      setError(true);
      await setFieldValue('password', '');
      return;
    }

    navigate('/');
  };

  return (
    <>
      <Helmet title="Sign in" />

      <div className="text-center">
        <h1 className="mb-2 text-2xl font-semibold text-slate-900">Sign in to Tailplane</h1>
        <p className="px-2 mb-6 text-sm text-slate-500">Enter your email and password to access your account.</p>
      </div>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        {(props) => <LoginForm error={error} {...props} />}
      </Formik>

      <div className="flex flex-col items-center mt-6 text-center">
        <p className="text-sm text-slate-500">
          Don't have an account?{' '}
          <a
            href="https://tailplane.com?ref=app-login"
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium transition rounded-sm text-primary hover:underline underline-offset-4 u-ring ring-offset-4"
          >
            Register for free now
          </a>
        </p>
        <p className="mt-1 text-sm text-slate-500">
          By continuing, you agree to our{' '}
          <a
            href="https://tailplane.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            className="underline rounded-sm underline-offset-4 hover:text-primary u-ring ring-offset-4"
          >
            Terms
          </a>{' '}
          and{' '}
          <a
            href="https://tailplane.com/policy"
            target="_blank"
            rel="noopener noreferrer"
            className="underline rounded-sm underline-offset-4 hover:text-primary u-ring ring-offset-4"
          >
            Policy
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default Login;
