import { useField } from 'formik';
import { Link } from '../../../../components/ui';

export const ResetPasswordLink = () => {
  const [{ value: email }] = useField<string>('email');

  return (
    <Link to={`/auth/reset-password?email=${email}`} className="font-normal text-primary hover:underline text-xs" tabIndex={99}>
      Forgot password?
    </Link>
  );
};
