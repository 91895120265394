import { useButton } from '@react-aria/button';
import { FocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { AriaButtonProps } from '@react-types/button';
import { forwardRef, PropsWithChildren, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { cn } from '../../../utils/cn';
import { InlineSpinner } from '../InlineSpinner';

export type ButtonProps = PropsWithChildren<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, className, onClick, ...props }, forwardedRef) => {
  const localRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    mergeProps(props, {
      isDisabled: props.disabled,
      onPress: onClick,
    }) as AriaButtonProps,
    localRef,
  );

  return (
    <FocusRing focusRingClass="u-focus-ring">
      <button {...buttonProps} ref={mergeRefs([localRef, forwardedRef])} className={className}>
        {children}
      </button>
    </FocusRing>
  );
});
Button.displayName = 'Button';

type SubmitButtonProps = PropsWithChildren<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    isSubmitting?: boolean;
  }
>;

const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(({ children, isSubmitting, ...rest }, forwardedRef) => (
  <Button {...rest} ref={forwardedRef} disabled={isSubmitting}>
    <span className={cn('transition-opacity', { 'opacity-0': isSubmitting })}>{children}</span>
    <div className={cn('inset-0 absolute flex items-center justify-center', { 'opacity-0': !isSubmitting })}>
      <InlineSpinner />
    </div>
  </Button>
));
SubmitButton.displayName = 'SubmitButton';

export { Button, SubmitButton };
