import { Formik, FormikHelpers } from 'formik';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import * as Yup from 'yup';
import { DataLoadingSpinner } from '../../../../components/ui';
import { useResetUserPassword } from '../../../../hooks/api/useResetUserPassword';
import { useVerifyResetPasswordToken } from '../../../../hooks/api/useVerifyResetPasswordToken';
import { ReturnToSignInLink } from '../../components/ReturnToSignInLink';
import { ChangePasswordForm } from './ChangePasswordForm';

type FormValues = {
  newPassword: string;
  confirmPassword: string;
};

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().required(),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
});

const ChangePassword = () => {
  const params = useParams<{ token: string }>();
  const token = params.token!;
  const email = useSearchParam('email') ?? '';
  const { isLoading: isVerifying, isSuccess: isVerified, isError: isVerificationError } = useVerifyResetPasswordToken(`${email}`, token);
  const { mutate: changePassword, isSuccess, error } = useResetUserPassword();

  const initialValues: FormValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const submit = ({ newPassword }: FormValues, { setFieldValue }: FormikHelpers<FormValues>) =>
    changePassword(
      { email, token, newPassword },
      {
        async onError() {
          await setFieldValue('newPassword', '');
        },
      },
    );

  return (
    <>
      <Helmet title="Change password" />

      {isVerifying && <DataLoadingSpinner />}
      {isVerificationError && (
        <div className="text-center text-red-500" role="alert">
          This password reset link is invalid. Go back to the sign in page to request a new one.
        </div>
      )}
      {isVerified && !isSuccess && (
        <>
          <div className="text-center">
            <h1 className="mb-2 text-2xl font-semibold text-slate-900">Set a new password</h1>
            <p className="px-2 mb-6 text-sm text-slate-500">
              Enter a new password for your account. You'll be able to use this password to sign in to Tailplane.
            </p>
          </div>

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
            {(props) => <ChangePasswordForm error={error} {...props} />}
          </Formik>
        </>
      )}
      {isVerified && isSuccess && (
        <div className="text-center">
          <h1 className="mb-2 text-2xl font-semibold text-slate-900">Password changed</h1>
          <p className="px-2 mb-6 text-sm text-slate-500">Your password has been changed. You can now sign in with your new password.</p>
        </div>
      )}

      <ReturnToSignInLink />
    </>
  );
};

export default ChangePassword;
