import { AnalyticsBrowser } from '@june-so/analytics-next';
import { useEffect, useState } from 'react';
import { config } from '../config';

export function useJune(writeKey: string) {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser>();

  useEffect(() => {
    // eslint-disable-next-line
    const loadAnalytics = async () => {
      if (!config.telemetryEnabled) {
        return;
      }

      const response = AnalyticsBrowser.load({
        writeKey,
      });
      setAnalytics(response);
    };
    void loadAnalytics().then();
  }, [writeKey]);

  return analytics;
}
