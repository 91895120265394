import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FullPageSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center">
    <FontAwesomeIcon icon={faSpinnerThird} spin className="w-8 h-8 text-primary" role="progressbar" />
  </div>
);

export default FullPageSpinner;
