import { Field, FieldAttributes } from 'formik';
import { cn } from '../../../utils/cn';

const FormInput = <T,>({ className, ...rest }: FieldAttributes<T>) => (
  <Field
    className={cn(
      'w-full bg-white border rounded-lg shadow-xs border-slate-300 text-slate-900 placeholder:text-slate-500 px-3.5 py-2.5 sm:text-sm focus:border-primary focus:ring-3 focus:ring-primary/20 transition disabled:opacity-50',
      className,
    )}
    {...rest}
  />
);

export default FormInput;
