import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DataLoadingSpinner = () => (
  <div className="flex items-center justify-center h-64 text-primary">
    <FontAwesomeIcon icon={faSpinnerThird} spin className="w-8 h-8 text-primary" role="progressbar" />
  </div>
);

export default DataLoadingSpinner;
