import { useMutation } from '@tanstack/react-query';
import { PasswordResetRequestsService } from '../../api';
import { ProblemDetails } from '../../types';

export const useRequestUserPasswordReset = () =>
  useMutation<
    void,
    ProblemDetails,
    {
      email?: string;
      client?: 'webapp' | 'bookings';
    }
  >({
    mutationFn: (request) => PasswordResetRequestsService.create(request),
  });
